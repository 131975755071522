import React, { forwardRef, useContext, useState, useEffect, useMemo, useRef, useReducer } from 'react';
import { DocumentPages } from './DocumentPages';
import { DocumentHeaders } from './DocumentHeaders';
import { CommentContext } from 'app/state/contexts/CommentContext';
import { Comment } from './comments/Comment';
import { EditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { VersionHistory } from './VersionHistory';
import { ParagraphChanges } from './ParagraphChanges';
import Tippy from '@tippyjs/react';
import { serialize } from 'app/slate/compareUtils';
import { i18n } from 'app/utils/i18n';
import { Bookmarks } from './bookmarks/Bookmarks';

const sideBySideItems = [1, 2, 3, 4, 5];
const documentItems = [1, 2, 5, 6];

const Sidebar = ({ isSideBySide, readOnly, setRevisionL }) => {
  const menuItems = useMemo(
    () => [
      { id: 1, label: <i className="fa-regular fa-list-ul" />, tooltip: i18n('doc-editor.sidebar-link.headers-tooltip') },
      { id: 2, label: <i className="fa-regular fa-file-lines" />, tooltip: i18n('doc-editor.sidebar-link.page-tooltip') },
      { id: 3, label: <i className="fa-regular fa-clock-rotate-left" />, tooltip: i18n('doc-editor.sidebar-link.change-history-tooltip') },
      { id: 4, label: <i className="fa-regular fa-pen-to-square" />, tooltip: i18n('doc-editor.sidebar-link.changes-tooltip') },
      { id: 5, label: <i className="fa-regular fa-message" />, tooltip: i18n('doc-editor-link.sidebar.comments-tooltip') },
      { id: 6, label: <i className="fa-regular fa-bookmark" />, tooltip: i18n('bookmarks.sidebar.title') },
    ],
    []
  );

  const { shadowHost } = useContext(EditorContext);
  const handleCommentFocusChange = (oldComment, newComment) => {
    if (oldComment !== newComment) {
      const element = shadowHost.shadowRoot.querySelector(`#anchor-${oldComment?.onId}`);
      element?.parentElement?.classList.remove('layout-leaf-comment');
    }
    return newComment;
  };

  const [isOpen, setIsOpen] = useState(true);
  const [activePage, setActivePage] = useState(menuItems[1]);
  const { comments, commentsFocusRequested, setCommentsFocusRequested } = useContext(CommentContext);
  const [activeComment, setActiveComment] = useReducer(handleCommentFocusChange);
  const { content } = useEditorSelector((editor) => editor.section);
  const containerRef = useRef(null);

  useEffect(() => {
    if (commentsFocusRequested) {
      setIsOpen(true);
    }
  }, [commentsFocusRequested]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleActiveComment = (newActiveComment) => {
    const element = shadowHost.shadowRoot.querySelector(`#anchor-${newActiveComment.onId}`);
    const y = element?.getBoundingClientRect().top + window.pageYOffset - 160;
    window.scrollTo({ top: y, behavior: 'smooth' });
    setActiveComment(newActiveComment);
  };

  const reorderedComments = useMemo(() => {
    let flatDocument = {};
    if (content && Object.keys(content).length !== 0) {
      flatDocument = serialize({ content });
    }
    return Object.entries(flatDocument)
      .map(([key, val]) => comments.filter((comment) => comment.onId === key))
      .filter((comment) => comment);
  }, [content, comments]);

  const pageSwitch = (visiblePage, containerRef) => {
    switch (visiblePage.id) {
      case 1:
        return <DocumentHeaders containerRef={containerRef} />;
      case 2:
        return <DocumentPages readOnly={readOnly} containerRef={containerRef} />;
      case 3:
        return <VersionHistory isSideBySide={isSideBySide} setRevisionL={setRevisionL} />;
      case 4:
        return <ParagraphChanges isSideBySide={isSideBySide} />;
      case 5:
        return (
          <div>
            {reorderedComments
              .filter((comment) => comment.onType !== 'comment')
              .map((comments) =>
                comments.map((comment) => (
                  <Comment comment={comment} key={comment.id} activeCommentId={activeComment?.id} onCommentClick={() => toggleActiveComment(comment)} />
                ))
              )}
          </div>
        );
      case 6:
        return <Bookmarks />;
      default:
        return <h3>Unknown page</h3>;
    }
  };

  useEffect(() => {
    if (isSideBySide) {
      setActivePage(menuItems[3]);
    }
    if (commentsFocusRequested) {
      setActivePage(menuItems[4]);
    }
    setCommentsFocusRequested(false);
  }, [commentsFocusRequested, menuItems, setCommentsFocusRequested, isSideBySide]); // eslint-disable-line react-hooks/exhaustive-deps

  const SidebarButton = forwardRef(({ page = {} }, ref) => {
    const togglePage = () => {
      setActivePage(page);
    };

    return (
      <button onClick={togglePage} className={`btn rounded-circle me-auto ms-auto mt-2 mb-2 p-0 ${page.id === activePage.id ? 'btn-primary' : ''}`} ref={ref}>
        {page.label}
      </button>
    );
  });

  const SidebarItems = () => {
    const relativeMenuItems = getRelativeMenuItems(menuItems, isSideBySide);
    return (
      <div className="menu">
        {relativeMenuItems?.map((page) => (
          <Tippy key={page.id} content={<p className="gaby-tooltip">{page.tooltip}</p>} placement="left">
            <SidebarButton page={page} />
          </Tippy>
        ))}
      </div>
    );
  };

  return (
    <div className={`sidebar ${isOpen ? '' : 'closed'}`}>
      <div className="controls columns col-1fr-auto">
        <i className="fa-regular fa-arrow-right-long sidebar-arrow" onClick={toggleSidebar} />
      </div>
      <div className="content--container">
        <div ref={containerRef} className="content">
          {pageSwitch(activePage, containerRef)}
        </div>
        <SidebarItems />
      </div>
    </div>
  );
};

function getRelativeMenuItems(menuItems, isSideBySide) {
  if (isSideBySide) {
    return menuItems.filter((item) => sideBySideItems.includes(item.id));
  }
  return menuItems.filter((item) => documentItems.includes(item.id));
}

const Header = ({ children }) => {
  return <div className="d-flex align-items-center justify-content-between p-3 border-bottom">{children}</div>;
};

Sidebar.Header = Header;

export default Sidebar;
