import React, { useState, useEffect } from 'react';
import { useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { i18n } from 'app/utils/i18n';
import { ConfirmationModal } from 'app/components';
import { setActiveSection } from 'app/state/redux/documentSlice';
import { useEditorDispatch, useEditorSelector } from 'app/state/contexts/EditorContext';
import { useGetDocumentTableOfContent } from 'app/api/documents/document-queries';
import { calculatePageOffset } from 'app/slate/components/page/utils';
import { useHistory } from 'react-router-dom';
import { PageVariables } from './PageVariables';
/**
 * Check if activeSection uuid equals page uuid.
 * If activeSection has no uuid assume the first page is selected.
 */
const isPageActive = (page, activeSection) => {
  if (activeSection?.uuid) {
    return page.uuid === activeSection.uuid;
  }

  return false;
};

export const DocumentPages = ({ containerRef, readOnly }) => {
  const section = useEditorSelector((editor) => editor.section);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const editorDispatch = useEditorDispatch();
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState(null);

  const handleClickPage = (index) => {
    const clickedPage = section.content[index];
    history.push({ search: history.location.search, hash: clickedPage.uuid });
    editorDispatch(setActiveSection(clickedPage));
  };

  useEffect(() => {
    const element = document.querySelector(`#preview-${activeSection?.uuid}`);
    if (shouldScroll(element, containerRef.current)) {
      const yOffset = -61; // offset for the sticky header
      const y = element.offsetTop - yOffset;
      containerRef.current.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [activeSection, containerRef]);

  if (selectedPage !== null) {
    return <PageVariables selectedPage={selectedPage} setSelectedPage={setSelectedPage} />;
  } else {
    return <PageOverview onClick={handleClickPage} onSelectPage={setSelectedPage} readOnly={readOnly} />;
  }
};

const PageOverview = ({ onClick, readOnly, onSelectPage }) => {
  const editor = useSlate();
  const locale = useEditorSelector((editor) => editor.locale);
  const section = useEditorSelector((editor) => editor.section);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const pDocument = useEditorSelector((editor) => editor.pDocument);

  const { data } = useGetDocumentTableOfContent(pDocument.revisionId, { locale, sectionSuperId: section.superId });
  const pageOffset = calculatePageOffset(pDocument.content?.pageNumbering);

  const sectionTitle = pDocument?.content?.sections.find((s) => s.superId === section.superId)?.name;

  if (data == null) {
    return null;
  }

  const startPage = data.range.start + pageOffset;
  const endPage = data.range.stop + pageOffset;

  return (
    <div className="preview--container">
      <div className="d-flex justify-content-between px-3 pt-4 align-items-center pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{sectionTitle}</div>
        <div className="text-gray-800 fs-7">
          {i18n('doc-editor.sidebar.page-prefix')}
          {startPage > 0 ? startPage : 0}-{endPage}
        </div>
      </div>
      <div className="px-3 w-100">
        {!editor.children.length
          ? null
          : editor.children.map((page, index) => (
              <div
                key={index}
                id={`preview-${page.uuid}`}
                className={`rounded cursor-pointer px-3 py-2 mb-2 ${
                  isPageActive(page, activeSection) ? 'active bg-blue-200 border-blue-800 text-blue-800' : ''
                }`}
                onClick={() => onClick(index, page)}
              >
                <PagePreview
                  index={index}
                  readOnly={readOnly}
                  pageNumber={startPage + index}
                  page={page}
                  active={page.uuid === activeSection?.uuid}
                  onSettingsClick={onSelectPage}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

const PagePreview = ({ index, readOnly, active = false, pageNumber, onSettingsClick }) => {
  const editor = useSlate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const deletePage = () => {
    Transforms.removeNodes(editor, { at: [index], match: (node) => node.type === 'page' });
    setShowDeleteConfirmationModal(false);
  };

  const handleClickDelete = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleSettingsClick = () => {
    onSettingsClick(pageNumber);
  };

  const pageOptions = [
    {
      icon: 'sliders',
      text: i18n('doc-editor.sidebar.page.link.settings'),
      disabled: readOnly,
      onClick: active ? handleSettingsClick : null,
    },
    {
      icon: 'trash',
      text: i18n('doc-editor.sidebar.page.link.delete'),
      disabled: readOnly || editor.children.length < 2,
      onClick: active ? handleClickDelete : null,
    },
  ];

  return (
    <>
      <div className="d-flex w-full justify-content-between">
        <div>{pageNumber < 1 ? '-' : pageNumber}</div>
        <div>
          {active && !readOnly
            ? pageOptions?.map((l, i) => {
                return (
                  <button
                    key={i}
                    disabled={l.disabled}
                    className={`bg-transparent ms-2 border-0 ${l.disabled ? 'text-disabled' : ''}`}
                    onClick={() => l.onClick?.()}
                  >
                    <i className={`fa-regular fa-${l.icon} ${l.disabled ? 'text-disabled' : 'text-gray-800'}`} />
                    <span className="ms-2">{l.text}</span>
                  </button>
                );
              })
            : null}
        </div>
      </div>
      <ConfirmationModal
        message={
          <>
            <i className="fa-regular fa-trash-can fs-1 mb-4" />
            <h3 className="confirm-delete-header">{i18n('doc-editor.confirm-delete')}</h3>
            <span className="text-gray-900 fs-7">{i18n('doc-editor.confirm-delete-info')}</span>
          </>
        }
        title={' '}
        show={showDeleteConfirmationModal}
        onConfirm={deletePage}
        onDecline={() => setShowDeleteConfirmationModal(false)}
      />
    </>
  );
};

const shouldScroll = (element, scrollElement) => {
  if (element == null) {
    return false;
  }

  const parentHeight = scrollElement.clientHeight;
  const elementTop = element.getBoundingClientRect().top;
  const elementBottom = element.getBoundingClientRect().bottom;

  if (elementTop < 0) {
    return true;
  }

  if (elementBottom > parentHeight) {
    return true;
  }

  return false;
};
