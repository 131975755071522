import { useEffect, useState, useRef, useCallback } from 'react';
import request from 'app/api/request';
import moment from 'moment';
import { useSelector } from 'react-redux';

/**
 * Custom hook to manage the lock of a section
 * @param {string} documentId - The document id
 * @param {string} sectionId - The section id
 * @returns {Object} - The lock information
 * @returns {boolean} hasLock - Whether the user has the lock
 * @returns {Object} lockInfo - The lock information
 * @returns {Function} deleteLock - The function to delete the lock
 *
 * @example
 * const { hasLock, lockInfo, deleteLock } = useLock(documentId, sectionId);
 *
 * if (!hasLock) {
 *  // Show a message
 * }
 */

const useLock = (documentId, sectionId) => {
  const [lockInfo, setLockInfo] = useState(false);
  const timer = useRef(null);
  const hasWritePermission = useSelector((state) => state.document.sectionPermissions)?.[sectionId] === 'WRITE';
  const isMounted = useRef(true);

  // Memoized getLock function
  const getLock = useCallback(async () => {
    try {
      const lock = await request.post(`/gaby/locks/${documentId}/${sectionId}`).then((res) => res.data);
      if (isMounted.current) {
        if (lock) {
          // Ensure component is still mounted
          setLockInfo(lock);
        }
      }
      return lock;
    } catch (message) {
      return console.error(message);
    }
  }, [documentId, sectionId]);

  // Memoized deleteLock function
  const deleteLock = useCallback(async () => {
    try {
      const res = await request.del(`/gaby/locks/${documentId}/${sectionId}`);
      if (isMounted.current) {
        // Ensure component is still mounted
        setLockInfo({});
      }
      return res;
    } catch (message) {
      return console.error(message);
    }
  }, [documentId, sectionId]);

  useEffect(() => {
    isMounted.current = true;
    if (hasWritePermission) {
      getLock().then(async (lock) => {
        if (lock?.isValidForUser && lock?.isValidNow && isMounted.current) {
          const currentTime = moment(new Date());
          const validUntil = moment(lock.validUntil, 'YYYY MM DD hh mm ss SSSZ');
          const timeout = validUntil.diff(currentTime);
          if (parseInt(timeout) === timeout && timeout > 0) {
            if (timer.current) {
              clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
              if (isMounted.current) {
                getLock();
              }
            }, timeout);
          }
        }
      });
    }

    return () => {
      isMounted.current = false; // Set isMounted to false on cleanup
      if (timer.current) {
        clearTimeout(timer.current);
      }
      deleteLock();
      timer.current = null;
    };
  }, [sectionId, documentId, hasWritePermission, getLock, deleteLock]);
  return { hasLock: hasWritePermission && !!lockInfo?.isValidForUser && !!lockInfo?.isValidNow, lockInfo, deleteLock };
};

export default useLock;
