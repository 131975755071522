import { useSlate } from 'slate-react';
import { Range, Editor } from 'slate';
import { forwardRef } from 'react';
import { insertLink, updateLinkBookmark } from '../../../slate/extensions/link';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useBookmarks } from 'app/utils/hooks/useBookmarks';
import { v4 as uuidv4 } from 'uuid';

export const LinkButton = forwardRef(({ icon, css }, ref) => {

  const editor = useSlate();
  const { selection } = editor;
  const hasSelectedText = selection && !Range.isCollapsed(selection);
  return (
    <button
      ref={ref}
      className={`${css}`}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      disabled={!hasSelectedText}
    >
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});

export const LinkContent = forwardRef((_, ref) => {
  const editor = useSlate();
  const { documentSuperId, superId: sectionId } = useEditorSelector((editor) => editor.section);
  const { createLink, bookmarks, isBookmarksLoading, updateLink } = useBookmarks(documentSuperId);
  const {
    content: { sections },
  } = useEditorSelector((editor) => editor.pDocument);
  const groupedBookmarks = Object.groupBy(
    bookmarks?.map((bookmark) => ({
      ...bookmark,
      sectionName: sections.find((s) => s.superId === bookmark.sectionId).name ?? '',
    })) || [],
    (bookmark) => bookmark.sectionName
  );

  const handleSubmit = (bookmarkId) => {
    const linkId = `lk-${uuidv4()}`;
    const { selection } = editor;
    if (!selection || Range.isCollapsed(editor.selection)) return;
    const [linkNode] = Editor.nodes(editor, {
      match: (n) => n.type === 'link',
    });
    if (!!linkNode) {
      const existingLinkId = linkNode[0].id;
      updateLink({ id: existingLinkId, bookmarkId });
      updateLinkBookmark(editor, existingLinkId, bookmarkId);
    } else {
      const label = Editor.string(editor, selection);
      createLink({ linkId, documentSuperId, sectionId, label, bookmarkId });
      insertLink(editor, linkId, bookmarkId, documentSuperId, sectionId);
    }
  };
  if (isBookmarksLoading) return <div>Loading...</div>;
  return (
    <div ref={ref} className={`bookmarkbar-content`}>
      {groupedBookmarks &&
        Object.keys(groupedBookmarks).map((sectionName) => (
          <div key={sectionName}>
            <h4>{sectionName}</h4>
            <div className="divider horizontal" />
            {groupedBookmarks[sectionName].map((b) => (
              <button key={b.bookmarkId} onClick={() => handleSubmit(b.bookmarkId)} className="link-button">
                {`Label: ${b.label}`}
              </button>
            ))}
          </div>
        ))}
    </div>
  );
});
