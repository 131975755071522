import { i18n } from '../../../../utils/i18n';
import { useSlate } from 'slate-react';
import { navigateTo } from 'app/slate/extensions/link';
import { useContext } from 'react';
import { EditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { removeBookmark } from 'app/slate/extensions/bookmark';
import { removeLink } from 'app/slate/extensions/link';
import { useBookmarks } from 'app/utils/hooks/useBookmarks';
const BookmarkList = ({ items }) => {
  const editor = useSlate();
  const { shadowHost } = useContext(EditorContext);
  const { documentSuperId, superId: sectionId } = useEditorSelector((editor) => editor.section);
  const { bookmarkAndLinks, isBookmarksAndLinksLoading, deleteBookmark, deleteLink } = useBookmarks(documentSuperId);
  const handleRemoval = async (id, type) => {
    switch (type) {
      case 'bookmark':
        deleteBookmark({ id, documentSuperId });
        removeBookmark(id, editor);
        break;
      case 'link':
        deleteLink({ id, documentSuperId });
        removeLink(id, editor);
        break;
      default:
        console.error('Wrong type when deleting, please use bookmark or linke');
    }
  };
  if (isBookmarksAndLinksLoading) return <div>Loading...</div>;
  return (
    <>
      <div className="bookmark-sidebar">
        {bookmarkAndLinks.map((bookmarkAndLink, index) => {
          const isBookmarkInSameSection = bookmarkAndLink.bookmark.sectionId === sectionId;
          return (
            <div key={index} className="bookmark-sidebar-content">
              <div className="bookmark-sidebar-bookmark-content">
                <i className="fa-solid fa-trash" onClick={() => handleRemoval(bookmarkAndLink.bookmark.bookmarkId, 'bookmark')} />
                <i
                  className={`fa-regular fa-bookmark ${!isBookmarkInSameSection && 'link-bookmark-disabled'}`}
                  onClick={() => {
                    isBookmarkInSameSection && navigateTo(shadowHost, bookmarkAndLink.bookmark.bookmarkId);
                  }}
                />
                <p>{bookmarkAndLink.bookmark.label}</p>
              </div>
              <i className="fa-regular fa-arrow-right bookmark-sidebar-content-icon" />
              <div className="bookmark-sidebar-link-content-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
                {bookmarkAndLink.links.length === 0 ? (
                  <p className="bookmark-sidebar-link-content-inner">{i18n('bookmarks.sidebar.no-links')} </p>
                ) : (
                  bookmarkAndLink.links.map((link, index) => {
                    const isLinkInSameSection = link.sectionId === sectionId;
                    return (
                      <div key={link.id} className="bookmark-sidebar-link-content-inner">
                        <p>{link.label}</p>
                        <i
                          className={`fa-regular fa-link ${!isLinkInSameSection && 'link-bookmark-disabled'}`}
                          onClick={() => {
                            isLinkInSameSection && navigateTo(shadowHost, link.linkId);
                          }}
                        />
                        <i className="fa-solid fa-trash" onClick={() => handleRemoval(link.linkId, 'link')} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="divider" />
    </>
  );
};

export const Bookmarks = ({ items }) => {
  return (
    <div className="preview--container">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('bookmarks.sidebar.title')}</div>
      </div>
      <div className="px-3 w-100">
        <BookmarkList />
      </div>
    </div>
  );
};
