import React, { useState, useContext } from 'react';
import { usePopper } from 'react-popper';
import { EditorContext } from 'app/state/contexts/EditorContext';
import Tippy from '@tippyjs/react';
import { i18n } from 'app/utils/i18n';

const ReadOnlyMark = React.memo(({ readOnly, lockOwner }) => {
  const { editorContainerRef } = useContext(EditorContext);
  const { firstName, lastName } = lockOwner || {};
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(editorContainerRef.current, popperElement, {
    placement: 'right-start',
  });

  if (!readOnly) {
    return null;
  }

  return (
    <Tippy
      content={
        <p className="gaby-tooltip">
          {firstName && lastName ? `${i18n('editor.readonly.lockedby')} ${firstName} ${lastName}` : `${i18n('editor.readonly.lockedby.unknown')}`}
        </p>
      }
      placement="left"
    >
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div className="read-only-mark">
          <i className="fa-regular fa-eye" />
        </div>
      </div>
    </Tippy>
  );
});

export default ReadOnlyMark;
