import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Editor } from 'app/components/editor/Editor';
import Navbar from 'app/components/editor/navbar/Navbar';
import Sidebar from 'app/components/editor/sidebar/Sidebar';
import { SubToolbar } from 'app/components/editor/subtoolbar/SubToolbar';
import { SubNavBar } from 'app/components/editor/navbar/SubNavbar';
import withMentions from 'app/slate/extensions/mentions';
import withImportArea from 'app/slate/extensions/import-area';
import withNotes from 'app/slate/extensions/notes';
import withPage from 'app/slate/extensions/page';
import withParagraph from 'app/slate/extensions/paragraph';
import withLayout from 'app/slate/extensions/layout';
import withImage from 'app/slate/extensions/image';
import withReferenceTable from 'app/slate/extensions/reference-table';
import withTableOfContent from 'app/slate/extensions/table-of-content';
import useLock from './useLock';
import ReadOnlyMark from 'app/components/editor/ReadOnlyMark';
import { usePermissions } from 'app/utils/hooks/document-permission';
import { CommentContextProvider } from 'app/state/contexts/CommentContext';
import withBookmarks from 'app/slate/extensions/bookmark';
import withLinks from 'app/slate/extensions/link';
import { useBookmarks } from 'app/utils/hooks/useBookmarks';

export const DocumentEditor = () => {
  const { documentId, sectionId } = useParams();
  const documentRevisionId = useQuery().get('revision');
  const sectionRevisionId = useQuery().get('sectionRevision');
  const translationId = useQuery().get('translation');
  const isDraft = useQuery().get('useDraft');
  const instance = { documentId, documentRevisionId, sectionId, sectionRevisionId, translationId };
  const [showFormatting, setShowFormatting] = useState(false);
  const { hasLock, lockInfo, deleteLock } = useLock(documentId, sectionId);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { sectionPermissions } = usePermissions(documentId);
  const showSubToolbar = Object.values(sectionPermissions).some((value) => value);
  const readOnly = !hasLock || !!isDraft || showFormatting;
  const toolbarComponents = { styling: !isReadOnly, insert: !isReadOnly, languange: true };
  const { deleteLink, deleteBookmark, createLink } = useBookmarks(documentId);
  useEffect(() => {
    setIsReadOnly(!hasLock || !!isDraft || showFormatting);
  }, [hasLock, isDraft, showFormatting]);

  const extensions = [
    withLayout({ markEmpty: true }),
    withMentions,
    withImportArea({ isReadOnly }),
    withImage({ isReadOnly }),
    withPage({ withButtons: true, isReadOnly }),
    withNotes,
    withParagraph({ withComments: true }),
    withReferenceTable({ isReadOnly }),
    withTableOfContent({ isReadOnly }),
    withBookmarks({ deleteBookmark, createLink }),
    withLinks({ deleteLink }),
  ];

  useEffect(() => {
    // Release the lock when the component unmounts or when the user exits the editor
    return async () => {
      if (hasLock) {
        await deleteLock();
      }
    };
  }, [hasLock, deleteLock]);

  return (
    <CommentContextProvider>
      <div className={`document-editor ${showSubToolbar ? 'sub-navbar-padding' : ''}`}>
        <Editor sectionHeader autoSave showFormatting={showFormatting} instance={instance} extensions={extensions} readOnly={readOnly}>
          <Navbar readOnly={isReadOnly} isDraft={isDraft} />
          <ReadOnlyMark readOnly={isReadOnly} lockOwner={lockInfo.lockOwner} />
          <SubNavBar visibile={showSubToolbar}>
            <SubToolbar enabled={toolbarComponents} showFormatting={showFormatting} setShowFormatting={setShowFormatting} />
          </SubNavBar>
          <Sidebar readOnly={isReadOnly} />
        </Editor>
      </div>
    </CommentContextProvider>
  );
};

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};
