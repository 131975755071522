import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Editor } from 'app/components/editor/Editor';
import withMentions from 'app/slate/extensions/mentions';
import withNotes from 'app/slate/extensions/notes';
import withPage from 'app/slate/extensions/page';
import withParagraph from 'app/slate/extensions/paragraph';
import withLayout from 'app/slate/extensions/layout';
import withImage from 'app/slate/extensions/image';
import withImportArea from 'app/slate/extensions/import-area';
import withReferenceTable from 'app/slate/extensions/reference-table';
import { useGetDocument } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts/UserContext';
import { mapLanguages } from 'app/utils/translations';
import withTableOfContent from 'app/slate/extensions/table-of-content';
import withBookmarks from 'app/slate/extensions/bookmark';
import withLinks from 'app/slate/extensions/link';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const extensions = [
  withLayout({ markEmpty: false }),
  withMentions,
  withImportArea({ readOnly: true }),
  withImage({ readOnly: true }),
  withPage({ withButtons: false, readOnly: true }),
  withNotes,
  withParagraph({ withComments: false }),
  withReferenceTable({ readOnly: true }),
  withTableOfContent({ readOnly: true }),
  withBookmarks({}),
  withLinks({}),
];

export const PdfViewer = () => {
  const { selectedOrganization } = useContext(UserContext);

  const { documentId, documentRevisionId } = useParams();
  const useDraft = useQuery().get('useDraft') || false;
  const pageBorders = useQuery().get('pageBorders') || false;
  const { data: doc } = useGetDocument(documentId, selectedOrganization, documentRevisionId, useDraft);
  const localeRaw = useQuery().get('locale') || doc?.content?.locale;
  const locale = localeRaw !== '' ? localeRaw : 'sv-SE';

  useEffect(() => {
    // TODO Make it so that we can render correct instead of this hack
    // When viewing the "pdf" no styles from the tool should be present
    ['head style:not([data-xrp-keep="true"])', 'head link:not([data-xrp-keep="true"])'].forEach((query) =>
      document.querySelectorAll(query).forEach((n) => n.parentNode.removeChild(n))
    );
  });

  if (!doc) {
    return null;
  }

  return (
    <div className="pdf-viewer">
      {doc.content.sections.map((section) => (
        <Editor
          key={section.superId}
          extensions={extensions}
          editorId={section.superId}
          withShadowDom={false}
          readOnly
          instance={{
            documentId: doc.superId,
            documentRevisionId: doc.revisionId,
            sectionId: section.superId,
            translationId: mapLanguages(doc, section.superId).find((l) => l.locale === locale)?.superId || section.superId,
          }}
          showErrors={false}
          pageBorders={pageBorders}
        >
          {' '}
        </Editor>
      ))}
    </div>
  );
};
